@import '../../../utils/variables';

.section-wrapper {
  .course-info {
    border-bottom: 1px solid $light-gray-color;
    margin-bottom: 1.1rem;
    padding-bottom: 1rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      h2 {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
    p {
      margin: 0.22rem 0;
      font-weight: 400;
    }
  }

  .section-info {
    .section-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .deletion-buttons {
        position: relative;

        button:last-child {
          margin-left: 1rem;
        }

        .confirmation-message {
          position: absolute;
          bottom: 115%;
          right: 0;
          text-align: center;
          color: $black;
          font-size: 0.65rem;
          text-transform: uppercase;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    .section-number {
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      h2.h1 {
        margin-top: 0.35rem;
        margin-bottom: 2rem;
      }
      input {
        margin-left: 1rem;
        width: 6ch;
      }
      .input-error {
        margin-left: -4.5rem;
        margin-top: 0.4rem;
      }
    }

    .field {
      margin-bottom: 1.8rem;

      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-weight: 500;
          margin: 1rem 0;
          svg {
            width: 1rem;
            margin-right: 0.6rem;
          }
        }
      }

      &.capacity {
        input {
          width: 6ch;
        }
      }
    }
  }

  .buttons {
    padding-bottom: 1.25rem;
    button {
      margin-right: 1rem;
    }
  }
}
