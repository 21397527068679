@import '../../utils/variables.scss';

#skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;

  &:focus {
    clip: auto;
    height: 60px;
    overflow: auto;
    position: absolute;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgba($yellow-color, 0.9);
    background-color: rgba($gray-color, 0.5);
    text-align: center;
  }
}
