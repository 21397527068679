#change-state-form {
  border-bottom: 1px solid #c0c1c2;
  padding-bottom: 1rem;

  .block-text {
    margin: 40px 0;
  }
  .buttons {
    margin-top: 1.8rem;
    margin-bottom: 1rem;

    button:first-child {
      margin-right: 0.8rem;
    }
  }

  div.radios {
    max-width: 400px;
    margin: 20px 0 30px;
  }
}
