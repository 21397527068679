@import '../../utils/variables';
@import '../../utils/mixins';

.default-effort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    margin: 0;
  }

  p {
    font-size: 15px;
    font-weight: bolder;
    text-transform: uppercase;
  }
}

.default-effort-container:first-child {
  position: relative;
  padding-left: 1rem;
  -webkit-print-color-adjust: exact;
  &::before {
    content: '';
    $left-yellow-bar-width: 6px;
    background-color: $yellow-color;
    position: absolute;
    top: 0;
    left: 0;
    width: $left-yellow-bar-width;
    height: 90%;
    border-radius: $left-yellow-bar-width/2;
  }
}

ul.course-ul {
  margin-top: 3rem;
  li.course-effort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;

    .content {
      flex-grow: 1;
      flex-shrink: 0;
      align-items: flex-start;
      flex-basis: 55%;
      max-width: 680px;

      @media screen and (max-width: 1100px) {
        flex-basis: 65%;
      }

      .course-number {
        padding-top: 2px;
      }
    }

    .components {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 40%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      margin-left: 2rem;

      @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr;
        flex: 30%;
      }

      .component {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .label {
          align-self: flex-start;
          margin-bottom: 0.4rem;
          padding: 3px 0.8em;
          font-size: 0.6rem;
        }
        input {
          width: 8ch;
          margin-bottom: 0;
        }
      }
    }
  }
}
