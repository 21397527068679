@import '../../utils/variables.scss';
@import '../../utils/mixins.scss';

#landing-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 3rem;

  @media screen and (max-width: $break-medium) {
    grid-template-columns: 1fr 1fr;
  }
}

.landing-page-tile {
  position: relative;
  box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.35);
  border-radius: 13px;
  padding: 1.2rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: $black;
  transition: all 400ms;
  transform: scale(1);

  h2 {
    @include bottom-bar;
    text-align: center;
    border-bottom: none;
    padding-bottom: 0.8rem;

    &:before {
      width: 50px;
      left: calc(50% - 25px);
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    img {
      height: auto;
      width: 100%;
      text-align: center;
    }
  }

  &.large {
    grid-column: span 2;
    flex-direction: row;

    h2 {
      text-align: left;
      display: inline-block;
      flex: 0;

      &:before {
        left: 0;
      }
    }

    p {
      margin: 0;
      font-size: 0.88rem;
      flex-basis: 48%;
    }

    .text {
      height: 12rem;
      flex: 1;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .image {
      margin-left: 1.4rem;
      width: 40%;
      flex-grow: 0;
    }
  }

  &.unauthorized {
    &:hover,
    &:focus {
      transform: scale(1);
    }
  }

  &:hover {
    box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.55);
    transform: scale(1.03);
  }
  &:focus {
    box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.55);
    outline: none;
    transform: scale(1.06);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 9px;
    border-radius: 13px 13px 0 0;
    background: black;
  }
}
