@import '../../utils/variables.scss';

.add-student-group-wrapper {
  section {
    border-width: 1px;
  }
}

#sg-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .form-end-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons {
    margin-top: 1rem;
    button {
      margin-bottom: 1.4rem;
      &:first-child {
        margin-right: 0.8rem;
      }
    }
  }

  @media (max-width: $break-medium) {
    flex-wrap: wrap;

    .form-end-wrapper {
      align-items: flex-start;
      margin-left: 0;
    }
  }
}

#sg-entry-form {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 4fr;
  grid-template-areas: 'program program program program' 'track track term year' 'groupname groupname groupname .' 'separator separator separator .' 'enrollment pos pos pos';
  grid-row-gap: 1.4rem;
  grid-column-gap: 2rem;
  margin-bottom: 1.4rem;
  flex-shrink: 0;
  width: 100%;
  max-width: 640px;

  > div {
    position: relative;

    .input-error {
      position: absolute;
      top: 100%;
    }

    &:nth-child(1) {
      grid-area: program;
    }
    &:nth-child(2) {
      grid-area: track;
    }
    &:nth-child(3) {
      grid-area: term;
    }
    &:nth-child(4) {
      grid-area: year;
    }
    &:nth-child(5) {
      grid-area: groupname;
      input {
        width: 32ch;
      }
    }
    &:nth-child(6) {
      grid-area: separator;
      h2 {
        padding-bottom: 0;
        margin: 0.75rem 0 0;
      }
    }
    &:nth-child(7) {
      grid-area: enrollment;
      input {
        width: calc(100% - 1.3rem);
      }
    }
    &:nth-child(8) {
      grid-area: pos;
    }
  }
}

.select-container {
  width: 100%;
}

#success-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: $yellow-color;
    height: 58px;
    width: 58px;
  }
}

#App.is-ie #sg-entry-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-shrink: 1;

  > div {
    margin-bottom: 1rem;
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 30%;
    }
    &:nth-child(5) {
      width: 100%;
    }
  }
}
