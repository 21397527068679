@import '../../utils/variables';

#update-pos-wrapper {
  .info-message,
  .warning-message {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .form-end-wrapper {
    width: 100%;
  }

  .bottom.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .archival-buttons {
    position: relative;

    button:last-child {
      margin-left: 1rem;
    }

    .confirmation-message {
      position: absolute;
      bottom: 115%;
      right: 0;
      text-align: center;
      color: $black;
      font-size: 0.65rem;
      text-transform: uppercase;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
