@import '../../utils/variables.scss';

ul.interactive.pos-list {
  padding-left: 2rem;
  margin-bottom: 2rem;

  li > a {
    $li-tb-padding: 0.5rem;
    $li-left-padding: 2rem;
    $li-right-padding: 1rem;
    $li-dashed-border: 2px;
    padding: calc(#{$li-tb-padding} + #{$li-dashed-border}) //top
      calc(#{$li-right-padding} + #{$li-dashed-border}) //right
      calc(#{$li-tb-padding} + #{$li-dashed-border}) //bottom
      calc(#{$li-left-padding} + #{$li-dashed-border}); //left
    margin-left: -$li-left-padding;

    .content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
      max-width: 840px;
      width: 100%;
      padding-right: 1rem;
    }

    &:focus {
      padding: $li-tb-padding $li-right-padding $li-tb-padding $li-left-padding;
    }
    &:focus,
    &:hover {
      .content {
        padding-right: 0;
      }
    }
  }
}

#App.is-ie ul.interactive.pos-list {
  li .content {
    display: flex;
    > span {
      flex-basis: 22%;
      margin-right: 3%;
    }
  }
}
