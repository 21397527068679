.student-group-enrollment {
  display: inline-flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  span {
    text-transform: uppercase;
  }
  .text-content {
    width: 170px;
    flex-direction: row;
    justify-content: flex-end;
    display: inline-flex;
    align-items: center;
  }
  .count {
    width: 60px;
    margin-right: 0.75rem;
    text-align: center;

    input {
      width: 70%;
      padding: 0.3rem;
      text-align: center;
    }
  }
  button {
    margin-left: 0.9rem;
  }
}
