@import '../../../utils/variables.scss';
$input-border: 2px;
$input-font-size: 13px;
$input-line-height: 1.4rem;
$input-tb-padding: 0.5rem;

.checkbox-input-row {
  display: grid;
  grid-template-columns: 3fr 2fr;

  .checkbox-input-col {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
  }

  .checkbox-input-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  legend {
    margin: 5px 5px 15px 5px;
  }

  li {
    $li-tb-padding: 0.6rem;
    $li-lr-padding: 0.8rem;
    $li-dashed-border: 2px;
    padding: calc(#{$li-tb-padding} + #{$li-dashed-border})
      calc(#{$li-lr-padding} + #{$li-dashed-border}) !important;
    width: 20ch;

    &.checked.checkboxComponent.checkbox-container {
      margin-bottom: 14px;
    }
  }

  .checkbox-input-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //padding: 2px;
    min-height: calc(
      #{$input-tb-padding} + #{$input-border} + #{$input-line-height} + #{$input-font-size}
    );
    > div {
      position: relative;

      .input-error {
        top: 0 !important;
        left: 100% !important;
        width: 115px;
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  input {
    border: $input-border solid $light-gray-color;
    background-color: #ffffff;
    font-size: $input-font-size;
    line-height: $input-line-height;
    padding: $input-tb-padding 0.65rem;
  }
}
