@import '../../utils/variables.scss';

.course-container {
  padding-top: 1rem;

  .label.archived {
    margin: 0 0 0.6rem 0.6rem;
  }

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.6rem;

    h3 {
      margin: 0.4rem 0 0.3rem;
    }
    h1 {
      margin: 0.3rem 0;
    }
    p {
      margin: 0.3rem 0 0.4rem;
    }
  }

  .completed {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    display: inline-block;
  }

  .component {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    margin-bottom: 1rem;

    .label {
      // min-width: 90px;
      margin-right: 1.5rem;
      text-align: center;
    }
  }

  .schedules {
    table {
      padding: 0;
      margin: 1rem 0;
    }

    tr.schedule {
      margin-bottom: 1rem;
      width: 100%;

      .state {
        text-align: center;
        div.label {
          width: calc(100% - 2.4em);
        }
      }

      .enrollment {
        text-align: right;
      }
    }

    td {
      padding-right: 3rem;
      padding-bottom: 1rem;
    }
  }
}
