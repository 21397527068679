@mixin left-bar {
  position: relative;
  padding-left: 1rem;
  -webkit-print-color-adjust: exact;
  &::before {
    content: '';
    $left-yellow-bar-width: 6px;
    background-color: $yellow-color;
    position: absolute;
    top: 0;
    left: 0;
    width: $left-yellow-bar-width;
    height: 100%;
    border-radius: $left-yellow-bar-width/2;
  }
}

@mixin bottom-bar {
  position: relative;
  padding-bottom: 0.3rem;
  -webkit-print-color-adjust: exact;
  &::before {
    content: '';
    $bottom-yellow-bar-height: 4px;
    background-color: $yellow-color;
    position: absolute;
    bottom: 0;
    height: $bottom-yellow-bar-height;
    width: 100%;
    border-radius: $bottom-yellow-bar-height/2;
  }
}

@mixin dot-before($dot-color: $yellow-color) {
  &:before {
    content: '';
    background-color: $dot-color;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    line-height: 1em;
    position: absolute;
    top: 1rem;
    left: 0.7rem;
  }
}
