@import '../../utils/variables';

#panel-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  pointer-events: none;

  #panel-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 400ms;
    background-color: rgba(255, 255, 255, 0.8);
  }

  #panel-content {
    transform: translateX(100%);
    transition: transform 400ms;
    width: 60vw;
    height: calc(100vh - 2.8rem);
    max-width: 570px;
    margin-left: auto;
    background-color: white;
    box-shadow: -2px 2px 8px 2px rgba(130, 130, 130, 0.45);
    padding: 1.5rem 2.2rem 2.4rem;
    overflow-y: scroll;

    @media print {
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }

    .form-message {
      margin-bottom: 1.5rem;
      max-width: unset;
    }

    > *:last-child {
      padding-bottom: 5rem;
    }
  }

  &.visible {
    pointer-events: all;

    #panel-bg-overlay {
      opacity: 1;
    }
    #panel-content {
      transform: translateX(0);
    }
  }
}
