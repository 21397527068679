@import '../../utils/variables.scss';

#schedules-list {
  padding-bottom: 2.2rem;
}
.schedules-results ul {
  li.schedule {
    display: flex;

    .content {
      flex-grow: 1;
      display: flex;
      > span {
        flex-basis: 180px;
        flex-shrink: 0;
      }

      .label {
        width: 11ch;
        text-align: center;
        font-size: 0.6rem;
      }
    }
  }
}
