@import '../../utils/variables.scss';

#update-terms-form {
  ul {
    max-width: 300px;
  }

  .buttons {
    margin-top: 1.5rem;

    button {
      margin-right: 1rem;
    }
  }

  .plans-of-study-error-message {
    margin-top: 1.67rem;
  }
}
