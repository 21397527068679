.term {
  h2 {
    margin-top: 1rem;
  }
  .view-term-courses {
    padding-bottom: 0.5rem;

    .assign-courses-button {
      margin: 0.5rem 0 0.7rem;
    }
  }
}
