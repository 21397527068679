// Validation messages and counter

.validation-message-wrapper {
  flex-basis: 100%;
  width: 100%;
  margin: 15px 0;
}
.validation-message-container {
  width: 100%;

  .validation-message-small {
    font-weight: 500;
    font-size: 0.9rem;
    display: inline-block;
    padding: 6px 8px 6px 6px;
    margin: 5px 0;
    border-radius: 4px;
    vertical-align: middle;

    svg {
      height: 0.8rem;
      margin-right: 5px;
    }

    &.warning {
      background: #fffae0;
      color: #8b5d00;

      svg {
        color: #f8b300;
      }
    }

    &.error {
      background: #ffeaea;
      color: #ca2121;

      svg {
        color: #ca2121;
      }
    }

    &.info {
      background: #f0f9fc;
      color: #006894;

      svg {
        color: #006894;
      }
    }

    &.valid {
      background: #5db35d;
      color: white;
    }
  }
}

.heading.error::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 11px;
  border-radius: 16px 0 0 16px;
  background: #ca2121;
}

.heading.warning::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 11px;
  border-radius: 16px 0 0 16px;
  background: repeating-linear-gradient(
    -45deg,
    #ffce00,
    #ffce00 3px,
    #f8b300 3px,
    #f8b300 6px
  );
}

.validation-counter {
  font-weight: 600;
  font-size: 0.9rem;
  position: absolute;
  border-radius: 20px;
  left: -45px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;

  svg {
    width: 0.9em;
    height: 0.9em;
  }

  &.warning {
    background: #fff2ac;
    color: #b28100;
  }

  &.error {
    background: #ffeaea;
    color: #ca2121;
  }

  &.valid {
    background: #ffffff;
    color: #5db35d;
  }
}
