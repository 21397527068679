@import '../../../utils/mixins';
@import '../../../utils/variables';

.hash-selector {
  display: flex;
  align-items: flex-end;
  margin: 1.2rem 5px 1rem;

  &.large div {
    font-size: 1rem;
  }
  div {
    margin-right: 1.5rem;
    padding-top: 0.42rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    color: $gray-color;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;

    &.active {
      color: $black;
      @include bottom-bar;
      padding-bottom: 0.5rem;
      -webkit-print-color-adjust: exact;

      span.top-label {
        color: $gray-color;
      }
    }

    &:focus {
      outline: none;
      background-color: $lightest-yellow-color;
      -webkit-box-shadow: 0px 0px 12px 3px rgba($light-yellow-color, 0.7);
      -moz-box-shadow: 0px 0px 12px 3px rgba($light-yellow-color, 0.7);
      box-shadow: 0px 0px 12px 3px rgba($light-yellow-color, 0.7);
    }

    &:last-child {
      margin-right: 0;
    }

    span.top-label {
      font-size: 0.7rem;
      padding-bottom: 0.2rem;
    }
  }

  .pipe-separator {
    width: 2px;
    border-radius: 1px;
    background-color: $light-gray-color;
    cursor: default;
    align-self: stretch;
  }
}
