@import '../../../utils/variables.scss';

.input-container,
.facts-input {
  display: inline-block;
  position: relative;
}
.input-error {
  color: $red-color;
  font-size: 0.7rem;
  padding: 4px 0 0 3px;
}
.input-validation-icons {
  position: absolute;
  top: 0;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;

  &.error {
    svg {
      color: $red-color;
    }
  }
  svg {
    color: $green-color;
  }
}
.optional-input-label {
  font-size: 0.6rem;
  text-align: left;
  color: $gray-color;
  font-weight: 500;
  p {
    padding-left: 3px;
    padding-top: 3px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
