@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,700&display=swap');
@import './utils/variables';
@import './utils/mixins';

html {
  font-size: 18px;
}

html,
body,
#root {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body,
button,
span,
textarea,
div {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noscroll {
  overflow: hidden;
}

/*
 * Type
 */
h1 {
  font-size: 1.3rem;
  font-weight: normal;
  position: relative;
  margin-bottom: 1.5rem;
  border-bottom: 0;

  &:not(.no-bar) {
    padding-left: 1rem;
    @include left-bar;
  }
}

h2 {
  font-size: 1.11rem;
  border-bottom: 1px solid $lighter-gray-color;
  padding-bottom: 0.8rem;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
  font-weight: normal;

  &.no-border {
    border-bottom: none;

    &.left-bar {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }

  &.h1 {
    font-size: 1.2rem;
    padding: 0.2rem 0 0.2rem 1rem;
    @include left-bar;
    border-bottom: none;
  }
}

h3 {
  font-size: 0.85rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
h3.alternative {
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 2rem 0 0.3rem;
}
.subheading {
  text-transform: uppercase;
  margin: 1rem 0 2rem;
  display: inline-block;
  font-weight: 500;
}

/*
 * Inputs
 */
a.unauthorized {
  cursor: not-allowed;
  opacity: 0.5;
  filter: grayscale(80%);
  span {
    opacity: 0.6;
  }
}
button {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1rem;
  padding: 0.8rem 1.1rem;
  border-radius: calc(1.3rem + 2px);
  border: 2px solid $yellow-color;
  color: black;
  cursor: pointer;
  outline: none;
  background-color: transparent;

  &.small {
    font-size: 0.7rem;
    padding: 0.3rem 0.65rem;
  }

  &.medium {
    font-size: 0.7rem;
    padding: 0.5rem 0.8rem;
  }

  &:disabled,
  &[disabled] {
    border-color: $light-gray-color;
    cursor: not-allowed;
    color: $light-gray-color;

    svg {
      color: $light-gray-color;
    }
  }

  &:focus,
  &:hover {
    &:not(:disabled),
    &:not([disabled]) {
      background-color: $yellow-color;

      svg {
        color: black;
      }
    }
  }

  svg {
    color: $yellow-color;
    padding: 0 0 0 0.5rem;
  }

  &.circle {
    flex-shrink: 0;
    height: 40px;
    width: 40px;
    border-radius: 22px;
    line-height: normal;
    border: 2px solid $yellow-color;
    position: relative;
    margin: 0;
    padding: 0;

    &.small {
      height: 30px;
      width: 30px;
    }

    &.edit {
      svg {
        padding: 0 0 0 2px;
      }
    }

    svg {
      padding: 0;
      color: $black;
    }
    &:after {
      border: solid $black;
      color: $black;

      &:hover {
        border: solid $yellow-color;
      }
    }
    &:disabled,
    &[disabled] {
      border-color: $light-gray-color;
      cursor: not-allowed;
      color: $light-gray-color;

      svg {
        color: $light-gray-color;
      }
    }

    &.check {
      &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 9px;
        width: 5px;
        height: 10px;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &.cross-x {
      &:before,
      &:after {
        position: absolute;
        left: 10%;
        top: 44%;
        margin-top: -1px;
        content: '';
        height: 2px;
        width: 60%;
        background: $black;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

label,
legend {
  text-transform: uppercase;
  color: $gray-color;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 5px;
  transition: opacity 400ms;

  &.error {
    color: $red-color;
  }

  &.disabled {
    color: $gray-color;
    opacity: 0.6;
  }
}
.select-container {
  display: inline-block;
}

input,
.select-container .select,
textarea,
option {
  border: 2px solid $light-gray-color;
  background-color: #ffffff;
  border-radius: 12px;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0.5rem 0.65rem;
  transition: opacity 400ms;

  &:focus,
  &:active {
    outline: none;
    border: 2px solid $yellow-color;
  }

  &.error {
    border-color: $red-color;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    border-color: $light-gray-color;
  }
}
.actionable {
  cursor: pointer !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*
 * Messaging
 */
.info-message,
.error-message,
.warning-message {
  color: white;
  padding: 0.85rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 7px;
  display: inline-block;
  z-index: 0;
  max-width: 520px;
  svg {
    color: white;
    margin-right: 0.4rem;
  }
}
.info-message {
  background-color: $light-blue-color;
  color: $dark-blue-color;
  svg {
    color: $dark-blue-color;
  }
}
.error-message {
  background-color: $red-color;
}
.warning-message {
  background-color: $light-yellow-color;
  color: $black;
  svg {
    color: $black;
  }
}
.error-notice {
  color: $red-color;
  position: relative;
  padding-left: 1.8rem;
  margin-left: -0.7rem;
  @include dot-before($red-color);
}
.label {
  border-radius: 2em;
  background-color: $lightest-gray-color;
  -webkit-print-color-adjust: exact;
  display: inline-block;
  padding: 4px 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  letter-spacing: 1px;
}

.circle-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: 700;

  &.large {
    width: 70px;
    height: 70px;
    border-radius: 40px;
    font-size: 2rem;
  }
}

/*
 * Lists
 */
ul.none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.new {
  position: relative;

  @include dot-before($yellow-color);

  &:before {
    left: -1rem;
    top: 0.25rem;
  }
}

ul.interactive {
  li > a {
    color: inherit;
    text-decoration: unset;
    width: 100%;
    display: block;
  }
  li:not(.with-anchor),
  li > a {
    $li-tb-padding: 0.6rem;
    $li-lr-padding: 1.5rem;
    $li-dashed-border: 2px;
    padding: calc(#{$li-tb-padding} + #{$li-dashed-border})
      calc(#{$li-lr-padding} + #{$li-dashed-border});
    margin-left: -1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    &.alert {
      @include dot-before($red-color);
      &:last-child() {
        margin-bottom: 1.6rem;
      }
    }
    &.new {
      @include dot-before($yellow-color);
      &:last-child() {
        margin-bottom: 1.6rem;
      }
    }

    svg {
      display: none;
      color: $yellow-color;
    }

    &:focus,
    &:focus-within {
      border: 2px solid $yellow-color;
      padding: $li-tb-padding $li-lr-padding;
    }
    &:hover,
    &:focus,
    &:focus-within {
      box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.5);
      border-radius: 8px;
      outline: none;

      svg {
        display: block;
      }
    }
  }
}
ul.checkboxes {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-left: 0;
    justify-content: flex-start;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;

  legend {
    padding: 0;
    margin: 0;
  }
}
.read-only {
  cursor: not-allowed !important;
}
/*
 * Layout
 */
section {
  padding: 1.6rem 0 0.5rem;
  border-top: 1px solid $light-gray-color;
  border-bottom: 1px solid $light-gray-color;
  margin-bottom: 1rem;

  h1 {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}
.left-bar {
  @include left-bar;
}

/*
 * Component Colors
 */
$component-colors: #006894 #d33c6e #1f1811 #cf400c #80225f #068475;
$component-text-colors: $white $white $white $white $white $white;
@each $current-color in $component-colors {
  $i: index($component-colors, $current-color);
  .component-#{$i}-bg {
    background-color: $current-color;
    color: nth($component-text-colors, $i);
  }
  .component-#{$i}-bg-light {
    background-color: rgba($current-color, 0.13);
  }
}

/*
 * Faculty Colors
 */
$faculty-colors: #068475 #b54573 #d42e63 #a11f1f #4a22e9 #000000 #507a3c #b55722
  #6c0e6f #6d677a;
$faculty-text-colors: $white $white $white $white $white $white $white $white
  $white $white;
@each $current-color in $faculty-colors {
  $i: index($faculty-colors, $current-color);
  .faculty-#{$i}-color {
    background-color: $current-color;
    -webkit-print-color-adjust: exact;
    color: nth($faculty-text-colors, $i);
  }
}
.inactive-faculty-color {
  background-color: $lightest-gray-color;
  color: $black;
}

.status-ready-bg {
  background-color: $green-color;
  color: $white;
}
.status-planning-bg {
  background-color: $warning-yellow-color;
  color: $black;
}
.status-projected-bg {
  background-color: $lightest-gray-color;
  color: $black;
}

/*
 * Internet Explorer 11 styling
 */
#App.is-ie {
  input {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

/*
 * Print Styling
 */
.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }

  .no-print {
    display: none;
  }
}
