@import '../../utils/variables';
@import '../../utils/mixins';

#export-form-wrapper {
  max-width: 300px;
}
.faculty-form-buttons-wrapper {
  button {
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

.faculty-header {
  height: 3.75rem;
  margin-bottom: 1rem;

  &:first-child {
    position: relative;
    padding-left: 1rem;
    -webkit-print-color-adjust: exact;
    &::before {
      content: '';
      $left-yellow-bar-width: 6px;
      background-color: $yellow-color;
      position: absolute;
      top: 0;
      left: 0;
      width: $left-yellow-bar-width;
      height: 90%;
      border-radius: $left-yellow-bar-width/2;
    }

    h1 {
      margin-bottom: 0;
    }
    p {
      font-size: 15px;
      font-weight: bolder;
      text-transform: uppercase;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}

.faculty-members-results {
  label {
    margin: 1.5rem 0 5px 5px;
  }
  input {
    width: 100%;
    max-width: 692px;
    display: block;
    margin-bottom: 1.1rem;
  }
  ul.interactive {
    li > a {
      width: 100%;
      display: flex;
      color: black;
      text-decoration: none;
      $li-tb-padding: 0.5rem;
      $li-left-padding: 2rem;
      $li-right-padding: 1rem;
      $li-dashed-border: 2px;
      padding: calc(#{$li-tb-padding} + #{$li-dashed-border}) //top
        calc(#{$li-right-padding} + #{$li-dashed-border}) //right
        calc(#{$li-tb-padding} + #{$li-dashed-border}) //bottom
        calc(#{$li-left-padding} + #{$li-dashed-border}); //left
      margin-left: -$li-left-padding;

      .content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding-right: 1rem;

        .flag {
          svg {
            display: block;
            color: $red-color;
            margin-left: -1.7rem;
          }
        }
      }

      &:focus {
        padding: $li-tb-padding $li-right-padding $li-tb-padding
          $li-left-padding;
        outline: none;
      }
      &:focus,
      &:hover {
        .content {
          padding-right: 0;
        }
      }

      .initials {
        margin-right: 1rem;
      }

      .name {
        flex-basis: 23%;
      }
      .appointment {
        flex-basis: 16%;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.8rem;
      }
      .rank-dept {
        flex-basis: 43%;
        font-size: 0.8rem;
      }

      .employee-id {
        flex-basis: 8%;
        font-size: 0.8rem;
      }
    }
  }
}
