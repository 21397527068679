@import '../../../utils/variables';
.connected-selector {
  display: flex;
  border: 2px solid $light-gray-color;
  border-radius: 12px;
  overflow: hidden;
  background-color: $light-gray-color;
  transition: opacity 400ms;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  > div {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 2px;
    padding: calc(0.6rem + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $gray-color;
    background-color: $white;

    &.disabled {
      cursor: not-allowed;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &:first-child {
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }
    &:last-child {
      margin-right: 0;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }

    &:focus,
    &:hover {
      border: 2px solid $light-gray-color;
      padding: 0.6rem;
      outline: none;
    }

    &.selected {
      background-color: $lightest-yellow-color;
      color: $black;
    }
  }
}
