@import '../../utils/variables';

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0 2.4rem;

  h1 {
    margin: 0;
  }
}

.button-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 560px;
  align-items: center;
  margin: 0.2rem 0 2.4rem;
  .Archive-buttons {
    position: relative;

    button:last-child {
      margin-left: 1rem;
    }
    .confirmation-message {
      position: absolute;
      bottom: 115%;
      right: 0;
      text-align: center;
      color: $black;
      font-size: 0.65rem;
      text-transform: uppercase;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
