@import '../../utils/variables';
@import '../../utils/mixins';

.main {
  padding-top: 1rem;

  .top-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 0;

    h1 {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
}

.schedule-view {
  .hash-selector {
    margin: 2rem 0 1.2rem;
  }
  .state {
    &.label {
      margin-bottom: 0.5rem;
    }

    &:not(.label) {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  .schedule-courses {
    padding-top: 0.7rem;
  }
  .course-content {
    width: 100%;
    .course-name {
      flex-grow: 1;
      padding-right: 1rem;
      display: flex;
      align-items: center;
    }
    .credit-hours {
      vertical-align: middle;
      font-weight: 400;
    }
    .enrollment {
      flex-basis: 30%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      svg {
        color: $yellow-color;
        margin-right: 1rem;
      }
    }
  }
}

svg.notes {
  height: 0.75rem;
}

.schedule-course-content {
  h3 {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 0.65rem;
    margin-top: 0;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
}
.instructional-component,
.assigned-student-group {
  display: inline-block;
  border-radius: 1rem;
  line-height: 1rem;

  span {
    margin: 0 1rem 0 0.7rem;
    margin: 0 1rem 0 0.7rem;
    font-weight: 500;
    font-size: 0.9rem;
    vertical-align: middle;
  }
}
.assigned-student-group {
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;

  .label {
    height: min-content;
    flex-shrink: 0;
  }
  span {
    font-weight: 400;
  }
}

.component-wrapper-list-item {
  margin-bottom: 0.7rem;
}

.instructional-component-wrapper {
  margin-bottom: 2rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  .instructional-component-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}
.section-details-wrapper {
  border-top: 1px solid $lightest-gray-color;
  min-width: 687px;
}

.section-details {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;

  > div {
    margin-right: 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
  }

  div.field {
    display: flex;
    color: black;

    &.na {
      color: $gray-color;
    }

    .icon-wrapper {
      width: 1.1rem;
      text-align: center;
      margin-right: 0.5rem;
      display: inline-block;
      svg {
        height: 0.8rem;
      }
    }
  }

  .toggle-container {
    margin-left: 1rem;
    margin-top: -4px;
  }

  .number {
    font-weight: 700;
    flex-basis: 10%;
  }

  .schedule {
    flex-basis: 17%;
  }

  .faculty-column {
    flex-basis: 34%;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    .employeeId {
      margin-left: 1.6rem;
    }

    .facultyMember {
      white-space: nowrap;
    }
  }
  .column {
    flex-basis: 30%;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    .capacity {
      margin-bottom: 0.5rem;
    }
  }
  .circle {
    margin-left: auto;
  }
}

.student-group {
  .details {
    display: flex;
    margin-bottom: 0.4rem;
    align-items: center;
    p {
      width: 300px;
      margin: 0;
      font-size: 0.9rem;
    }
    span.sg-name {
      margin-left: 1rem;
      line-height: 0.9rem;
    }
  }
  ol.group-list {
    counter-reset: sg-course-counter;
    list-style: none;
    $list-left-offset: 60px;
    padding-left: $list-left-offset;
    margin-bottom: 2rem;

    li {
      counter-increment: sg-course-counter;
      position: relative;
      margin-bottom: 0.42rem;
      font-size: 0.9rem;

      &:before {
        content: counter(sg-course-counter);
        position: absolute;
        left: -$list-left-offset;
        width: $list-left-offset - 20px;
        text-align: center;
        font-weight: 700;
        font-size: 0.8rem;
      }

      .course-number {
        font-size: 0.8rem;
      }

      .course-name {
        flex-grow: 0;
        padding-right: 0.5rem;
      }
    }
  }
}

#export-schedule {
  margin-left: 1rem;
}

@media screen and (max-width: 1254px) {
  .section-details {
    white-space: initial;
  }
}
