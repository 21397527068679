@import '../../utils/variables.scss';

.main.plan-of-study-container {
  padding-top: 1rem;

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.6rem;

    h1 {
      margin-bottom: 0;
    }
  }
}

#program-name {
  margin: 0;
  padding: 0.2rem 0 0.1rem;
}

#concentration-name {
  margin: 0;
  padding: 0.3rem 0 0.3rem 1px;
}

#plan-of-study-meta {
  margin: 0;
  padding: 0.1rem 0 0.4rem;
}

section {
  border-width: 0;
  margin-bottom: 2rem;

  .top-container {
    margin: 3rem 0 3rem;
  }
}
