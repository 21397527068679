@import '../../utils/variables.scss';

#faculty-view-wrapper {
  .browse-faculty-wrapper {
    position: relative;
    margin-bottom: 5rem;

    button:nth-child(1) {
      position: absolute;
      left: 3px;

      svg {
        padding-left: 0;
        padding-right: 9px;
      }
    }

    button:nth-child(2) {
      position: absolute;
      right: 3px;
    }
  }

  .top-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    h3 {
      margin: 0.4rem 0 0.3rem;
    }
    h1 {
      margin: 0.3rem 0;
    }
    p {
      margin: 0.3rem 0 0.4rem;
    }
  }

  section {
    margin-top: 2rem;
  }

  .faculty-notes-wrapper {
    h1 {
      display: inline-block;
      margin: 1rem 1rem 1rem 0;
    }
    .collapsible:focus:before {
      box-shadow: none;
    }
    .notes-viewer {
      overflow: hidden;
      overflow-y: auto;
      max-height: 140px;
      font-size: 0.8rem;
      line-height: 1rem;
      white-space: pre-wrap;
    }
    .notes-container {
      .input-container {
        width: 100%;
      }
      .facts-input {
        display: flex;
        flex-basis: 100%;
      }
      textarea {
        width: 100%;
        height: 140px;
        resize: none;
      }
    }
  }

  #work-plans {
    position: relative;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        margin: 0.5rem 0;
      }
    }
  }

  .other-teaching-item-inputs {
    display: grid;
    grid-template-columns: 5fr 1fr 8fr;
    grid-column-gap: 1.4rem;
  }

  #other-teaching-item-description-input {
    width: 550px;
  }

  #effort-input {
    width: 80px;
  }

  .work-activity-form {
    max-width: 1200px;
    padding: 20px 0;

    .work-activity {
      display: grid;
      grid-template-columns: 2fr 4fr 1.25fr;
      grid-column-gap: 2.4rem;
      flex-shrink: 0;
    }

    .work-activity-tu {
      display: grid;
      grid-template-columns: 5fr 5fr 9fr 2fr;
      grid-column-gap: 2.4rem;
      flex-shrink: 0;
    }
    .facts-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    input {
      width: 100%;
    }
    button {
      margin-right: 0.8rem;
    }
  }

  .faculty-work-activity-error {
    margin-top: 1.5rem;
  }

  .hash-selector {
    margin: 0px -10px 3rem -10px;
    padding: 0 10px 0 10px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 101;

    @media print {
      position: relative;
    }

    div {
      padding-bottom: 0.55rem;
      margin-right: 2rem;
    }
  }

  span.effort-heading {
    font-size: 1rem;
  }

  .work-plan {
    .top-content {
      margin-top: 1.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      &.allocation-form-open {
        border-bottom: 1px solid #cecece;
      }

      span.effort-heading {
        margin: 1.5rem 0;
        display: inline-block;
      }

      .error-message {
        margin-top: 1rem;
      }
    }

    .allocation-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .allocation {
        display: flex;
        flex-direction: column;

        .effort-labels {
          color: $gray-color;
          text-transform: uppercase;
          display: inline-block;
          letter-spacing: 1px;
          margin-right: 2rem;
          margin-bottom: 7px;
          font-size: 17px;
        }

        .expected-effort {
          display: inline-block;
          margin: 0 12rem 0 1.5px;
        }
      }
    }

    .effort-total {
      display: flex;
      flex-direction: row-reverse;
    }

    &:empty {
      height: 200px;
      $container-padding: 0;
      $container-height: 100%;
      $container-skeleton: linear-gradient(
        white #{$container-height},
        transparent 0
      );

      $title-height: 32px;
      $title-width: 100px;
      $title-position: #{$container-padding} 30px;
      $title-skeleton: linear-gradient(
        $lightest-gray-color #{$title-height},
        transparent 0
      );

      $desc-line-height: 16px;
      $desc-line-skeleton: linear-gradient(
        $lightest-gray-color #{$desc-line-height},
        transparent 0
      );
      $desc-line-1-width: 300px;
      $desc-line-1-position: #{$container-padding} 80px;
      $desc-line-2-width: 220px;
      $desc-line-2-position: #{$container-padding} 100px;
      $desc-line-3-position: #{$container-padding} 120px;
      $desc-line-4-position: #{$container-padding} 140px;

      $blur-width: 200px;
      $blur-size: #{$blur-width} calc(#{$container-height});

      &:after {
        content: '';
        display: block;
        width: 100;
        height: 100%;

        background-image: linear-gradient(
            90deg,
            rgba(white, 0) 0,
            rgba(white, 0.8) 50%,
            rgba(white, 0) 100%
          ),
          $title-skeleton, $desc-line-skeleton, $desc-line-skeleton,
          $container-skeleton;

        background-size: $blur-size, $title-width $title-height,
          $desc-line-1-width $desc-line-height,
          $desc-line-2-width $desc-line-height, 100% 100%;

        background-position: -50% 0, $title-position, $desc-line-1-position,
          $desc-line-2-position, 0 0;

        background-repeat: no-repeat;
        animation: loading 2.6s infinite, fadein 0.6s;
      }
      &.alternate {
        &:empty {
          &:after {
            background-image: linear-gradient(
                90deg,
                rgba(white, 0) 0,
                rgba(white, 0.8) 50%,
                rgba(white, 0) 100%
              ),
              $title-skeleton, $desc-line-skeleton, $desc-line-skeleton,
              $desc-line-skeleton, $desc-line-skeleton, $container-skeleton;

            background-size: $blur-size, $title-width $title-height,
              $desc-line-1-width $desc-line-height,
              $desc-line-2-width $desc-line-height,
              $desc-line-1-width $desc-line-height,
              $desc-line-2-width $desc-line-height, 100% 100%;

            background-position: -50% 0, $title-position, $desc-line-2-position,
              $desc-line-1-position, $desc-line-3-position,
              $desc-line-4-position, 0 0;

            background-repeat: no-repeat;
            animation: loading-alt 2.6s infinite, fadein 0.6s;
          }
        }
      }
      @keyframes loading {
        to {
          background-position: 100% 0, $title-position, $desc-line-1-position,
            $desc-line-2-position, 0 0;
        }
      }
      @keyframes loading-alt {
        to {
          background-position: 100% 0, $title-position, $desc-line-2-position,
            $desc-line-1-position, $desc-line-3-position, $desc-line-4-position,
            0 0;
        }
      }
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .work-type {
    margin-bottom: 3.2rem;
    .work-type-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      border-bottom: 1px solid $lighter-gray-color;
      h2 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.9rem;
        border-bottom: none;
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    .schedule-course-list {
      margin: 1.5rem 0;
      .title {
        display: flex;
        align-items: center;
      }
      .state {
        margin-left: 1.2rem;
        margin-top: 2px;

        &:not(.label) {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
    }

    button {
      margin-top: 0.4rem;

      &.small {
        margin-top: 0;
      }
    }

    table {
      width: 100%;
      margin-bottom: 1rem;
      th {
        text-transform: uppercase;
        text-align: left;
        font-weight: 500;
        font-size: 0.8rem;
        color: $gray-color;
        &.effort {
          font-weight: 700;
          text-align: center;
          color: $black;
        }
      }

      tr {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        $margin-right-value: 1rem;
        > th,
        > td {
          margin-right: $margin-right-value;
        }
        &.three-items {
          :nth-child(1) {
            flex-basis: calc(30% - #{$margin-right-value});

            @media print {
              max-width: 25%;
            }
          }
          :nth-child(2) {
            flex-basis: calc(55% - #{$margin-right-value});

            @media print {
              max-width: 65%;
            }
          }
          :nth-child(3) {
            margin-right: 0;
            flex-basis: 15%;
            text-align: right;
            margin-left: 0;

            @media print {
              max-width: 10%;
            }
          }
          th,
          td {
            &.can-edit {
              margin-left: -3rem;
            }
          }
        }

        &.four-items {
          :nth-child(1) {
            flex-basis: calc(23% - #{$margin-right-value});

            @media print {
              max-width: 15%;
            }
          }
          :nth-child(2) {
            flex-basis: calc(22% - #{$margin-right-value});

            @media print {
              max-width: 20%;
            }
          }
          :nth-child(3) {
            flex-basis: calc(43% - #{$margin-right-value});

            @media print {
              max-width: 60%;
            }
          }
          :nth-child(4) {
            margin-right: 0;
            margin-left: 0;
            flex-basis: 13%;
            text-align: right;

            @media print {
              max-width: 5%;
            }
          }
          th,
          td {
            &.can-edit {
              margin-left: -4rem;
              @media print {
                margin-left: 3rem;
                text-align: center;
              }
            }
          }
        }

        &.new:before {
          top: 40%;
        }
      }

      .edit-work-item {
        margin-left: 1rem;
        margin-right: -3rem;
        display: inline;
        button.small {
          margin-top: 0;
          margin-left: 0.5rem;
          transform: translateY(-2px);
        }
      }
    }
  }
}
#faculty-member-info {
  display: flex;
  align-items: center;
  margin-bottom: 2.6rem;

  .initials {
    margin-right: 1.7rem;
  }

  .top-container {
    .meta-info {
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: 0.3rem;
        transform: translateX(-6px);
        padding: 4px 0.9em;
        font-size: 0.68rem;
      }

      h1 {
        padding: 0;
        margin: 0;
      }

      span {
        line-height: 1.5em;
        font-size: 0.8rem;
        font-weight: 500;

        &.appointment {
          font-size: 0.8rem;
          font-weight: 700;
          text-transform: uppercase;
        }

        a {
          color: $black;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    > button {
      flex-shrink: 0;
    }
  }
}

#set-allocation-form {
  margin-top: 1.4rem;
  max-width: 680px;

  h2 {
    border-bottom: none;
    padding: 0;
  }

  .select-wrapper {
    width: 300px;
  }

  .set-default-allocation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #apply-default-values {
    margin-left: 1rem;
  }

  .select-container {
    max-width: 300px;
    margin: 0 1.4rem 1.4rem 0;
  }

  .effort-inputs-container {
    display: flex;
    flex-wrap: wrap;

    .allocation-input {
      margin: 1rem 1rem 0 0;
    }
    > * {
      .input-container {
        width: 100%;
        .facts-input {
          width: 100%;
          input {
            width: calc(100% - 1.3rem - 4px);
          }
        }
      }
    }
    .set-allocation-error-message {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    #submit-set-allocation {
      padding-right: 0.6rem;
    }
    button {
      margin-top: 1rem;
      margin-bottom: 1.4rem;
    }

    :nth-child(1) {
      margin-right: 0.8rem;
    }
  }
}
