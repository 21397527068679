@import '../../../utils/variables';

.accordion-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  &.deletion-enabled {
    grid-template-columns: 95% 5%;
  }

  .button-box {
    position: relative;
    padding-left: 10px;
  }

  .delete-options {
    transition: opacity 300ms;
    font-size: 0.6rem;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;

    &.buttons-visible {
      text-align: center;
      width: 110px;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }
    button {
      margin: 0 5px;
    }
  }
}

.accordion {
  margin: 0.5rem 0 1rem;
  z-index: 1;
  transition: all 601ms;

  &.shift-left {
    transform: translateX(-50px);
  }

  &.deletion-enabled {
    margin-right: -5%;
  }

  // &.is-removed {
  //   overflow: hidden;
  //   opacity: 0.5;
  //   max-height: 0;
  //   transition: max-height 500ms ease-in-out;
  // }

  .heading,
  .accordion-content {
    transform-origin: top center;
  }
  $border-width: 2px;
  $padding-lr-value: 1.2rem;
  $padding-tb-value: 1rem;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.4);
    border-radius: 16px;
    padding: $padding-tb-value $padding-lr-value;
    cursor: pointer;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 16px;

      box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.5);
      opacity: 0;
      transition: opacity 300ms;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    img {
      width: 0.9rem;
      height: 25px;
      width: 25px;
      transition: transform 400ms;
      padding: $border-width;

      &:focus {
        padding: 0;
        border: $border-width solid $yellow-color;
        border-radius: 15px;
        outline: none;
      }
    }
    &.expanded img {
      transform: rotate(180deg);
    }
  }

  .accordion-content-wrapper {
    max-height: 0;
    transition: all 601ms;
    overflow: hidden;
    position: relative;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    transform: translateY(-16px);
    z-index: 1;
    padding: 0;
    box-shadow: 1px 3px 3px 1px rgba(165, 165, 165, 0);

    &.expanded {
      box-shadow: 1px 3px 3px 1px rgba(165, 165, 165, 0.4);
    }

    &.collapsed {
      visibility: hidden;

      &.animating {
        visibility: visible;
      }
    }

    .accordion-content {
      padding: 1.7rem 2.2rem 1.5rem;
    }
  }
}
