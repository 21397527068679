.schedule-course-notes-wrapper {
  .notes-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;

    h2 {
      margin: 0 1rem 0 0;
    }
  }

  .input-container {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
  }

  .facts-input {
    display: flex;
    flex-basis: 100%;
    textarea {
      width: 100%;
      min-height: 220px;
      flex-grow: 1;
      resize: none;
    }
  }

  .notes-viewer {
    font-size: 0.8rem;
    line-height: 1rem;
    white-space: pre-wrap;
  }

  .error-message {
    margin-top: 1.4rem;
  }

  #form-end-wrapper {
    margin-top: 1rem;
  }
}
