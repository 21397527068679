#App {
  display: flex;
  flex-direction: column;
  min-width: 760px;

  @media print {
    display: block;
  }

  #main-content:focus {
    outline: none;
  }

  > .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @media print {
      flex-direction: column;
      display: block;
    }

    main {
      flex-grow: 1;
      padding: 1.8rem 3.6rem;
      position: relative;
    }
  }
}
