@import '../../utils/variables.scss';

.locations-list {
  .location-results {
    table {
      text-align: left;
      width: 100%;
      max-width: 900px;
      table-layout: fixed;

      td,
      th {
        padding: 20px 35px 20px 0;
        &.new:before {
          top: 1.5rem;
        }
      }

      colgroup {
        col {
          width: 20%;
        }
        col:first-of-type {
          width: 1%;
        }
        col:nth-of-type(2) {
          width: 45%;
        }
        col:nth-of-type(3) {
          width: 20%;
        }
      }

      .location-type::first-letter {
        text-transform: uppercase;
      }
    }
    thead tr th {
      text-transform: uppercase;
      color: #757575;
      font-size: 0.9rem;
      font-weight: 500;
    }
    label {
      margin: 1.5rem 0 5px 5px;
    }
    input {
      width: 100%;
      max-width: 692px;
      display: block;
      margin-bottom: 1.1rem;
    }

    .location-items {
      tbody {
        tr td.new::before {
          margin-top: 5px;
        }
      }
    }
  }
}

.add-location-sect {
  border-width: 1px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.update-location-sect {
  padding-bottom: 20px;
  margin-bottom: 2rem;
}

#add-location {
  width: 100%;
  max-width: 692px;

  .add-location-entry-form {
    display: grid;
    grid-gap: 0rem;
    max-width: 480px;
    grid-template-areas: 'building' 'capacityType';
    grid-template-columns: 1fr;
    > * {
      .input-container {
        width: 100%;
        max-height: 138px;
        label[for='capacity'] {
          margin-top: 0;
        }

        .facts-input {
          width: 100%;
          max-height: 50px;
          input {
            width: calc(100% - 1.3rem - 4px);
          }
        }
      }
    }

    .building-unit-input {
      grid-area: building;
      max-width: 480px;
      height: 138px;
    }

    .capacity-type-wrapper {
      grid-area: capacityType;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100px;
      .location-capacity {
        grid-area: capacity;
        width: 110px;
        height: 110px;
        .input-container {
          .facts-input {
            #capacity-input {
              margin-bottom: 0.4rem;
            }
          }
        }
      }
      .location-type {
        grid-area: type;
        width: 300px;
        label[for='type'] {
          margin-top: 0;
        }
      }
    }
  }
  .updating .building-unit-input .input-container .facts-input .error {
    top: 0;
  }
}

#form-end-wrapper {
  margin: 0.6rem 0 0.8rem;

  .buttons {
    button {
      margin-right: 1rem;
    }
  }

  .error-message {
    margin-bottom: 1rem;
  }

  .bottom.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .archival-buttons {
    position: relative;

    button:last-child {
      margin-left: 1rem;
    }

    .confirmation-message {
      position: absolute;
      bottom: 115%;
      right: 0;
      text-align: center;
      color: $black;
      font-size: 0.65rem;
      text-transform: uppercase;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
