@import '../../../utils/variables.scss';

.collapsible {
  transition: max-height 600ms;
  overflow: hidden;
  position: relative;

  &.collapsed {
    max-height: 0;
    visibility: hidden;
    &.animating {
      visibility: visible;
      overflow: hidden;
    }
  }
  &.visible {
    overflow: visible;

    &.animating {
      overflow: hidden;
    }
  }
  &:focus {
    outline: none;
    &:before {
      position: absolute;
      content: '';
      left: -10px;
      height: 100%;
      width: 10px;
      border-radius: 5px;
      box-shadow: -20px 0px 12px 3px rgba($light-yellow-color, 0.78);
    }
  }
  section {
    margin-bottom: 0;
  }
}
