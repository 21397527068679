@import '../../utils/variables.scss';

.update-term-courses-form {
  ul {
    max-width: 1000px;
    margin-bottom: 0.4rem;
  }

  fieldset {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: visible;
    border: 2px solid #eee;
    border-radius: 4px;
    width: 100%;
    margin-left: -1%;
    padding: 1%;
  }

  .buttons {
    margin-top: 1rem;
    margin-bottom: 3rem;

    button {
      margin-right: 1rem;
    }
  }

  .plans-of-study-error-message {
    margin-top: 1.67rem;
  }
}
