@import '../../../utils/variables.scss';

ul li:not(.with-anchor).checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.read-only {
    cursor: not-allowed;
    label {
      cursor: not-allowed;
    }
  }

  &.checked {
    background-color: $light-transparent-yellow-color;

    span.checkbox {
      background-color: $yellow-color;
      border-color: $yellow-color;

      &:after {
        display: block;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span.checkbox {
    flex-shrink: 0;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    border: 2px solid #c0c1c2;
    position: relative;
    margin: 0 1rem 0 0;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  label {
    color: $black;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: none;
    pointer-events: none;
  }
}
