@import '../../utils/variables.scss';

nav {
  background-color: $yellow-color;
  width: $sidebar-width;
  flex-shrink: 0;
  height: 100vh;
  top: 0;
  position: -webkit-sticky;
  position: sticky;

  @media print {
    height: auto;
    position: relative;
    width: 100%;
    display: none;
  }

  ul.navigation {
    padding-right: 1.8rem;
    padding-top: 2rem;
    letter-spacing: -1px;

    li {
      padding: 0.2rem 0;
      margin: 0.6rem 0;

      &.unauthorized {
        display: none;
      }

      > span,
      > a,
      ul li a {
        text-decoration: none;
        color: $brown-text-color;

        .icon svg {
          color: $brown-text-color;
        }
        &.active {
          color: $black;
          background-color: $light-yellow-color;

          .icon svg {
            color: $black;
          }
        }
      }

      > a span {
        border-radius: 0 96px 96px 0;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
      }
      > a.active span {
        background-color: $light-yellow-color;
      }
      span {
        padding-left: 1.2rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        position: relative;

        .icon {
          margin-right: 0.6rem;
          position: relative;
          line-height: 0.5rem;
          svg {
            width: 1rem;
            max-height: 1rem;
          }
        }
      }

      ul {
        margin-top: 0.75rem;

        li {
          margin: 0;
          padding: 0;

          a {
            padding: 0.4rem 0.8rem 0.4rem 3.6rem;
            border-radius: 0 96px 96px 0;
            display: block;
          }
        }
      }

      &.active,
      a.active {
        span {
          color: $black;
          svg {
            color: $black;
          }
        }

        span .icon {
          &:before {
            content: '';
            position: absolute;
            top: 115%;
            left: 9%;
            height: 2px;
            width: 80%;
            border-radius: 1px;
            background-color: $black;
            color: $black;
          }
        }
      }
    }
  }
}

#App.is-ie {
  ul.navigation .icon svg {
    height: 22px;
  }
}
