.course-option-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.course-number {
    font-weight: 700;
    font-size: 0.75rem;
    margin-right: 0.7rem;
    width: 9ch;
    flex-shrink: 0;
  }

  .course-name {
    display: inline-block;
    width: 100%;
  }

  .credit-hours {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    flex-shrink: 0;
    white-space: nowrap;
  }

  .course-enrollment {
    font-size: 0.7rem;
    font-weight: 400;
    flex-shrink: 0;
    white-space: nowrap;
  }
}
