/* Layout */
$sidebar-width: 250px;

/* Breakpoints */
$break-medium: 1200px;

/* Colors */
$white: #ffffff;
$black: #000000;
$black-color: $black;
$brown-text-color: #614200;
$yellow-color: #f8b300;
$warning-yellow-color: #ffce00;
$dark-blue-color: #006894;
$light-blue-color: #f0f9fc;
$light-yellow-color: #ffe34b;
$lightest-yellow-color: #fef8e1;
$light-transparent-yellow-color: rgba($light-yellow-color, 0.5);
$gray-color: #757575;
$light-gray-color: #c0c1c2;
$lighter-gray-color: #cecece;
$lightest-gray-color: #e3e3e3;
$red-color: #d72323;
$green-color: #168316;
