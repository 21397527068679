.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0 2.4rem;

  h1 {
    margin: 0;
  }
}
