@import '../../utils/variables.scss';

.main {
  padding-top: 1rem;

  &.student-group-view {
    .label {
      margin-bottom: 0.5rem;
    }
    .term .label {
      margin-bottom: 0;
    }

    .state:not(.label) {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 700;
    }
  }

  .top-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 0;

    h1 {
      margin-bottom: 0;
    }
  }
}

.update-term-courses-wrapper {
  margin: 10px 0 35px 0;

  .error-message {
    margin-bottom: 15px;
  }
}

#student-group-info {
  > h1,
  h3,
  p {
    margin: 0;
    padding: 0.3rem 0;

    &:last-child {
      padding-bottom: 0.4rem;
    }
  }
}

section {
  border-width: 0;
  margin-bottom: 2rem;

  .top-container {
    margin: 3rem 0 3rem;
  }
}

form#set-plan-of-study {
  max-width: 800px;
  padding-top: 1rem;

  .buttons {
    margin-top: 1.3rem;
  }
}

.term h2 {
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    width: 150px;
  }
}

.button-box {
  position: relative;
  padding-left: 10px;
}

.delete-options {
  transition: opacity 300ms;
  font-size: 0.6rem;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;

  &.buttons-visible {
    text-align: right;
  }

  span {
    display: block;
    margin-bottom: 5px;
  }
  button {
    margin: 0 5px;
  }
}
