@import '../../utils/variables.scss';

.course-list {
  .course-results {
    label {
      margin: 1.5rem 0 5px 5px;
    }
    input {
      width: 100%;
      max-width: 692px;
      display: block;
      margin-bottom: 1.1rem;
    }
  }
}
