@import '../../../utils/variables';

.toggle-slider {
  display: flex;
  @media print {
    display: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.2;

    .toggle {
      cursor: not-allowed;
      &:focus {
        border-color: transparent;
      }
    }
  }

  .toggle-label {
    padding: 2px 0.4rem;
  }

  .toggle {
    background-color: $lightest-gray-color;
    width: 46px;
    height: 22px;
    border-radius: 14px;
    border: 2px solid transparent;
    cursor: pointer;

    &:focus {
      outline: none;
      border: 2px solid $yellow-color;
    }

    .circle {
      width: 22px;
      height: 20px;
      border-radius: 11px;
      background-color: white;
      transition: all 400ms;
      margin-top: 1px;
      margin-left: 1px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 12px;
        width: 12px;
        color: $gray-color;
      }
    }

    &.on {
      background-color: $light-transparent-yellow-color;

      .circle {
        background-color: $yellow-color;
        transform: translateX(100%);

        svg {
          color: $white;
        }
      }
    }

    &.confirmed {
      background-color: green;

      .circle {
        background-color: white;
        transform: translateX(100%);

        svg {
          color: green;
        }
      }
    }

    &.unconfirmed {
      background-color: $yellow-color;

      .circle {
        background-color: white;

        svg {
          color: $yellow-color;
        }
      }
    }
  }
}
