@import '../../utils/variables.scss';

$header-gap: 1rem;

header {
  display: flex;
  flex-direction: row;
  padding: $header-gap 0;
  border-bottom: 1px solid #e3e3e3;

  > div:first-child {
    width: $sidebar-width;
    text-align: right;

    img {
      padding: 0.3rem 0 0.3rem $header-gap;
      margin-right: -5px;
      // border-right: 2px solid #e3e3e3;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: $header-gap;
    flex-grow: 1;
    padding-right: $header-gap * 2;
    align-items: center;
  }
  #right-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  #logout-link {
    font-size: 0.85rem;
    color: black;
  }

  img {
    width: 140px;
    height: auto;
  }
}
