.add-course-wrapper {
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
}

#course-add-wrapper,
.course-add-wrapper {
  max-width: 700px;

  .error-message {
    margin-top: 1rem;
  }

  .buttons {
    margin: 40px 0;
    button:first-child {
      margin-right: 0.8rem;
    }
  }
}
