@import '../../../utils/variables';

.schedule-picker {
  h4 {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $gray-color;
    margin-top: 1.5rem;
    margin-bottom: 0.35rem;

    &:first-child {
      margin-top: 0.25rem;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &.time {
      .connected-selector {
        margin-left: 1rem;
      }

      label {
        font-size: 0.7rem;
        margin: 0 5px 5px 0;
      }

      .options {
        text-align: center;
      }
    }
  }
  span.colon {
    line-height: 2.5rem;
    padding: 0 0.6rem;
  }
  .select {
    width: 7ch;
  }
}
