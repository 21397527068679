@import '../../utils/variables';

#modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  z-index: 103;
  pointer-events: none;
  opacity: 0;
  transition: opacity 700ms;

  &.visible {
    transition: opacity 450ms;
    pointer-events: all;
    opacity: 1;
  }
}

#modal-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  // backdrop-filter: blur(2px);
}

#modal-contents {
  max-width: 90%;
  background-color: white;
  box-shadow: 0 5px 12px 4px rgba(177, 177, 177, 0.5);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  z-index: 5;
  font-size: 1.4rem;

  &:focus {
    // box-shadow: 0px 0px 12px 3px rgba($light-yellow-color, 0.78);
    outline: 0;
  }

  img,
  svg {
    margin-bottom: 1.5rem;
  }

  .timeout-modal {
    width: 420px;
    max-width: 80vw;
    text-align: center;

    .top {
      color: $yellow-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.3rem 0;
      svg {
        width: 3rem;
        height: auto;
      }
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      svg {
        margin-left: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
}
