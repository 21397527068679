@import '../../utils/variables.scss';

ul.interactive.sg-list {
  padding-left: 2rem;
  margin-bottom: 2rem;

  li > a {
    $li-tb-padding: 0.5rem;
    $li-left-padding: 2rem;
    $li-right-padding: 1rem;
    $li-dashed-border: 2px;
    padding: calc(#{$li-tb-padding} + #{$li-dashed-border}) //top
      calc(#{$li-right-padding} + #{$li-dashed-border}) //right
      calc(#{$li-tb-padding} + #{$li-dashed-border}) //bottom
      calc(#{$li-left-padding} + #{$li-dashed-border}); //left
    margin-left: -$li-left-padding;

    &.alert {
      &:before {
        left: 0.9rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-right: 1rem;

      > span {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .dates {
      flex-basis: 34%;
      max-width: 300px;
    }
    .group-name {
      flex-basis: 25%;
    }
    .plan-of-study-message {
      flex-grow: 1;
      color: $red-color;
    }

    &:focus {
      padding: $li-tb-padding $li-right-padding $li-tb-padding $li-left-padding;
    }
    &:focus,
    &:hover {
      .content {
        padding-right: 0;
      }
    }
  }
}
