@import '../../utils/variables';

#update-work-item-wrapper {
  .top {
    margin-bottom: 1.2rem;
  }
}

#work-item-form-wrapper {
  #entry-form > div {
    margin-bottom: 1.2rem;
  }
  .name-and-delete {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .name {
      flex: 1;
      .input-container {
        width: 100%;
      }
    }

    .deletion-buttons {
      position: relative;
      margin-bottom: 0.4rem;
      min-width: 140px;
      text-align: right;

      button:last-child {
        margin-left: 1rem;
      }

      .confirmation-message {
        position: absolute;
        bottom: 115%;
        right: 0;
        text-align: center;
        color: $black;
        font-size: 0.65rem;
        text-transform: uppercase;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }

  .form-end-wrapper {
    .buttons {
      margin-top: 2rem;
      button:last-child {
        margin-left: 1rem;
      }
    }
  }

  .facts-input {
    display: block;
    width: 100%;

    input {
      width: calc(100% - 1.3rem - 4px);
    }
  }

  .actual-effort {
    .facts-input input {
      width: 120px;
    }
  }

  .description {
    .input-container {
      width: 100%;

      textarea {
        width: calc(100% - 1.3rem - 4px);
      }
    }
  }
}
