@import '../../utils/variables';
@import '../../utils/mixins';

.main {
  padding-top: 1rem;

  .top-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 0;

    h1 {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
}

.effort-totals-container {
  display: flex;
  flex-wrap: wrap;
  text-align: right;

  span {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
.teaching-effort-totals {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.schedule-course-list {
  margin: 2rem 0 3.5rem 0;

  .other-teaching-container {
    position: relative;
    border-radius: 16px;
    box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.5);
    margin-bottom: 5px;
    min-height: 65px;
    margin-top: 5px !important;

    &.new:before {
      top: 25px;
    }

    .other-teaching-details {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .other-teaching-label {
      margin-left: 10px;
      background-color: white;
      font-size: 0.9rem;

      @media print {
        font-size: 0.7rem;
        width: 40px;
      }
    }

    .other-teaching-description {
      position: absolute;
      left: 300px;
      font-size: medium;
      max-width: 50%;
      overflow-wrap: normal;
      max-height: 90%;
      overflow: auto;

      @media print {
        left: 20%;
      }
    }
    .other-teaching-effort {
      position: absolute;
      right: 15.5%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
    }
    .other-teaching-delete {
      right: 40px;
      margin-left: 100px;
    }
  }

  .faculty-courses {
    padding-top: 0.7rem;

    .accordion {
      page-break-inside: avoid;
    }

    .heading {
      margin-top: 0;
    }

    .course-content {
      width: 100%;
      .course-name {
        flex-grow: 1;
        padding-right: 1rem;
        display: flex;
        align-items: center;
      }
      .credit-hours {
        vertical-align: middle;
      }
      .enrollment {
        flex-basis: 30%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        svg {
          color: $yellow-color;
          margin-right: 1rem;
        }
      }
    }
  }
}

#add-prospective-courses-btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.add-course-reveal-wrapper {
  margin: 1.3rem 0 2.5rem 0;
}
.add-proposed-course-wrapper {
  margin: 2rem 0;
}

.course-wrapper-tu {
  min-width: 1100px;

  @media print {
    min-width: auto;
  }
}

.faculty-course-content {
  .instructional-component-wrapper .instructional-component-header {
    margin-top: 15px;
  }

  .instructional-component {
    background: none;
  }

  .instructional-component-wrapper {
    margin-bottom: 2rem;
    page-break-inside: avoid;
    &:last-of-type {
      margin-bottom: 0;
    }
    .instructional-component-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      div:last-child {
        display: flex;
        align-items: center;
      }
    }
    .default-effort {
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 1rem;
    }
  }
  .section-details-wrapper {
    border-top: 1px solid $lightest-gray-color;

    &.planned {
      border-top: none;
    }
  }
  .section-details {
    display: grid;
    grid-template-columns: 5fr 1fr;
    min-height: 50px;
    padding-top: 0.5rem;
    align-items: center;
    padding-bottom: 0.5rem;
    white-space: normal;

    &.planned {
      transition: all 601ms;
    }

    &.removed-planned {
      opacity: 0.5;
    }

    .section-effort,
    .section-buttons,
    .section-status,
    .section-effort-number {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;

      .effort-input-tu {
        margin-right: 18px;
      }
    }

    .section-buttons {
      button:last-child {
        margin-left: 5px;
      }
    }

    .section-effort {
      @media print {
        margin-left: 1rem;
        margin-right: 0;
      }
    }

    .section-effort-number {
      font-weight: 400;
      font-size: 1rem;
      margin-right: 0;

      .facts-input {
        padding: 0;
      }
      input {
        padding: 0.3rem;
      }
      .facts-input,
      input {
        width: 50px;
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
      }
    }
    .section-labels {
      display: flex;
      align-items: center;
      flex-basis: 70%;
      padding: 12px;
      justify-content: space-between;
      @media print {
        margin-left: 5px;
      }

      &.planned {
        border: 2px dashed #cccccc;
        border-radius: 6px;
        color: darken(#cccccc, 40%);
      }

      &.assigned {
        padding: 12px 14px 12px 0;
      }

      .modifier {
        width: 25%;
        margin-left: 2rem;
        text-align: center;
        @media screen and (max-width: 1000px) {
          width: 25%;
        }
        @media print {
          width: 25px;
          margin-left: 0;
          margin-right: 2rem;
        }
      }

      .modifier-label {
        @media print {
          margin-left: 1rem;
          margin-right: 1rem;
          font-size: 0.5rem;
          padding: 2px 5px;
        }
      }

      .section-fields {
        display: flex;
        align-items: center;
        width: 100%;

        > div {
          margin-right: 1rem;
          font-size: 0.8rem;
          font-weight: 500;
          &:last-child {
            margin-right: 0;
          }
          @media print {
            margin-right: 0.5rem;
          }
        }
      }

      .section-status {
        text-transform: uppercase;
      }
    }

    div.field {
      display: flex;
      color: black;

      &.na {
        color: $gray-color;
      }

      .icon-wrapper {
        width: 1.1rem;
        text-align: center;
        margin-right: 0.5rem;
        display: inline-block;
        svg {
          height: 0.8rem;
        }
      }
    }

    .number {
      font-weight: 700;
      flex-basis: 10%;
    }
    .schedule,
    .capacity,
    .location {
      flex-basis: 30%;
    }
    .circle {
      margin-left: auto;
    }
  }
}
