@import '../../../utils/variables.scss';

.radio-legend {
  color: $black;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: none;
  pointer-events: none;
}

div.radios {
  list-style-type: none;
  padding: 0;
  margin: 40px 0;
}

label.radio-container {
  $label-tb-padding: 0.6rem;
  $label-lr-padding: 1.5rem;
  $label-dashed-border: 2px;
  padding: calc(#{$label-tb-padding} + #{$label-dashed-border})
    calc(#{$label-lr-padding} + #{$label-dashed-border});
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  border-radius: 0.5rem;
  color: $black;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;

  &.read-only {
    cursor: not-allowed;
  }

  &.checked {
    background-color: $light-transparent-yellow-color;

    span.radio {
      background-color: $yellow-color;
      border-color: $yellow-color;

      &:after {
        display: block;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: darken(#c0c1c2, 10%);
  }
  &:focus,
  &:focus-within {
    border: 2px solid $yellow-color;
    padding: $label-tb-padding $label-lr-padding;
  }
  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: 2px 2px 6px 2px rgba(165, 165, 165, 0.5);
    border-radius: 8px;
    outline: none;

    svg {
      display: block;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span.radio {
    flex-shrink: 0;
    height: 22px;
    width: 22px;
    border-radius: 15px;
    border: 2px solid #c0c1c2;
    position: relative;
    margin: 0 1rem 0 0;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      margin-left: -5px;
      background: white;
      margin-top: -5px;
      top: 50%;
      width: 10px;
      border-radius: 10px;
      height: 10px;
    }
  }
}
