.add-faculty-sect {
  padding-top: 0;
}

#faculty-member-entry-form {
  display: grid;
  grid-gap: 1.4rem;
  max-width: 660px;
  grid-template-areas: 'fname lname' 'email eid' 'appt rank' 'dept dept' 'dates dates';
  grid-template-columns: 1fr 1fr;
  > * {
    .input-container {
      width: 100%;
      .facts-input {
        width: 100%;
        input {
          width: calc(100% - 1.3rem - 4px);
        }
      }
    }
  }
  .first-name {
    grid-area: fname;
  }
  .last-name {
    grid-area: lname;
  }
  .email-address {
    grid-area: email;
  }
  .employee-id {
    grid-area: eid;
  }
  .appointment {
    grid-area: appt;
  }
  .rank {
    grid-area: rank;
  }
  .department {
    grid-area: dept;
  }
  .dates {
    grid-area: dates;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    &.update {
      justify-content: space-between;
    }

    .start-date {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;

      h3 {
        margin: 0.3rem 0 0.35rem 4px;
        font-weight: 500;
      }

      .selects {
        display: flex;
        flex-direction: row;
        > div {
          margin-right: 1rem;
          .select {
            width: 9ch;
          }
        }
      }
    }
  }
}

.rollover-form {
  max-height: 15rem;

  .buttons {
    button {
      margin: 1rem 5px 3rem 5px;
    }
  }

  li {
    list-style-type: none;
    margin-top: 1rem;

    span {
      margin-right: 1rem;
    }
  }
}

#form-end-wrapper {
  margin: 2.1rem 0 0.8rem;

  .buttons {
    button {
      margin-right: 1rem;
    }
  }

  .error-message {
    margin-bottom: 1rem;
  }
}
