@import '../../../utils/variables.scss';
.select-container {
  position: relative;
  transition: opacity 400ms;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    .clear-value {
      &:focus {
        outline: none;
      }
      svg {
        color: $light-gray-color;
        &:focus {
          outline: none;
        }
      }
    }
  }
  &.optional {
    padding-bottom: 0.7rem;
  }
  .optional-input-label {
    position: absolute;
    margin-bottom: 52px;
    p {
      padding-left: 3px;
      padding-top: 3px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &:focus {
    outline: none;

    .select {
      border-color: $yellow-color;
    }
  }

  .clear-value {
    position: absolute;
    right: 2rem;
    top: calc(50% - 15px);
    padding: 4px 8px;
    border-right: 1px solid $gray-color;
    svg {
      color: $gray-color;
      transform: translateY(1px);
    }
  }

  .select {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    line-height: 1.4rem;
    &.unchanged {
      color: $gray-color;
      svg {
        color: $light-gray-color;
      }
    }

    svg {
      width: 0.9rem;
      height: 25px;
      transition: transform 200ms;
      color: $gray-color;
    }
    &[aria-expanded='true'] {
      border-color: $yellow-color;
      img {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    $options-border-width: 2px;
    $options-padding: 7px;
    position: absolute;
    top: 110%;
    left: 0;
    width: calc(100% - #{$options-border-width * 2 + $options-padding * 2});
    text-align: left;
    padding: $options-padding/2 $options-padding;
    border: $options-border-width solid $light-gray-color;
    border-radius: 6px;
    background-color: white;
    font-size: 0.9rem;
    z-index: 1;
    max-height: 60vh;
    overflow-y: scroll;
    margin-bottom: 2rem;
  }

  .option {
    padding: 5px 4px;
    margin: 3px 0;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: $light-transparent-yellow-color;
    }
    &:focus {
      outline: 0;
      border: 2px solid $yellow-color;
      padding: 3px 2px;
    }

    &.unselectable {
      text-align: center;
      cursor: not-allowed;
      color: $gray-color;
      &:hover {
        background-color: unset;
      }
    }
  }

  .first-after-toplist {
    position: relative;
    padding: 8px 4px 5px 4px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px solid #c0c1c2;
    }

    &:focus,
    &:active {
      padding: 6px 2px 3px 2px;
    }
  }

  .last-toplist-item {
    padding: 5px 4px 8px 4px;

    &:focus,
    &:active {
      padding: 3px 2px 6px 2px;
    }
  }

  .top-list {
    div {
      padding: 5px 4px;
      margin: 3px 0;
    }

    .top-list-heading {
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
}
