li.course .content,
.course-content {
  display: flex;
  align-items: center;

  span.course-number {
    font-weight: 700;
    font-size: 0.9rem;
    margin-right: 0.7rem;
    width: 9ch;
    flex-shrink: 0;
  }

  .credit-hours {
    font-weight: 400;
    font-size: 0.7rem;
    margin-left: 0.55rem;
    flex-shrink: 0;
    text-transform: uppercase;
  }
}

li.course a {
  text-decoration: none;
  color: black;
}

ul:not(.interactive) {
  li.course {
    margin: 1rem 0;
  }
}
