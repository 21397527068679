#redirecting {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  > span {
    margin-top: 4rem;
  }

  img {
    width: 250px;
    height: auto;
    margin: 0 1.5rem;
  }
}
